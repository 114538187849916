export const formattedDate = (date) => {
  var today = new Date(date);
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = String(today.getFullYear());

  today = dd + "/" + mm + "/" + yyyy;
  return today;
};

export const nextMonthFormattedDate = (date) => {
  var nextDate = new Date(date);
  var dd = String(nextDate.getDate()).padStart(2, "0");
  var mm = String(nextDate.getMonth() + 2).padStart(2, "0");
  var yyyy = String(nextDate.getFullYear());

  nextDate = mm + "/" + dd + "/" + yyyy;
  return nextDate;
};
